.text-zone {
    left: 12% !important;
    width: 100% !important;

    .about {
        max-width: 50% !important;
    }

    p {
        font-size: 18px !important;
    }
}

.timeline {
    max-width: 50%;
    float: right;
    margin-top: 10%;
    margin-right: 5%;

    ul {
        width: 100%;
        position: relative;
    }
}





@media screen and (max-width: 1200px) {
    .text-zone {
        max-width: 100% !important;
    }

    .timeline {
        max-width: 100%;
        float: right;

        ul {
            position: relative;
        }
    }

 
}