.menu {
  max-width: 10%;
}


.list {
  position: relative;
  text-align: center;
  width: 100;
}

.logo {
    display: block;
    padding: 8px 0;

    img {
        display: block;
        margin: 8px auto;
        width: 80px;
        height: auto;


        &.sub-logo {
            width: 50px;
        }
    }
}

.sub-logo-name {
    font-size: 28px;
    color: white;
    display: block;
    margin: 8px auto;
    text-decoration: none;
}

.hamburger-menu {
    display: none;
}


nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 25%;
    // margin-top: -120px;
    width: 100%;

    a {
      font-size: 30px;
      color: #ababab;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: #f1f1f1;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 18px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

    
    }

    a.home-link {
      &:after {
        content: 'HOME';
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.portfolio-link {
      &:after {
        content: 'PORTFOLIO';
      }
    }

    a.icon-link {
      &:after {
        color: #f54157;

      }
    }

    a.active {
      svg {
        color: #f54157;
      }
    }
  // }

  .ul-icon {
    position: relative;
    top:30%;
        // width: 10%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    // margin-top: 200px;

    li {
        a {
            padding: 7px 0;
            display: block;
            font-size: 20px;
            line-height: 16px;

            &:hover svg {
                color: #f54157;
                opacity: 1;
            }
        }
    }
  }
}

//SIDEBAR

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  // padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #a0a2ac;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  // padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}



@media screen and (max-width: 1200px) {
  .nav-bar {
    max-width: 65px;
    min-height: 100vh;
  }  

  .hamburger-menu {
    display: block;
  }

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left:9px;
    top: 36px;
}
}